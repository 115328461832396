// @flow strict
import React from 'react';
import Author from './Author';
import Contacts from './Contacts';
import Copyright from './Copyright';
import Menu from './Menu';
import Toggle from './Toggle';
import styles from './Sidebar.module.scss';
import { useSiteMetadata } from '../../hooks';


const Sidebar = ({ isIndex }) => {
  const { author, copyright, menu, darkModeOn } = useSiteMetadata();

  return (
    <div className={styles["sidebar"]}>
      <div className={styles["sidebar__inner"]}>
        <Author author={author} isIndex={isIndex} />
        <Menu menu={menu} />
        {darkModeOn ? <Toggle /> : null}
        <Contacts contacts={author.contacts} />
        <Copyright copyright={copyright} />
      </div>
    </div>
  );
};

export default Sidebar;
